﻿.dialog-hidden {
    display: none;
}

.dialog-visible {
}

.dialog-bg, .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
    opacity: .5;
    z-index: 100;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
}

.modal-content {
    input[type=text], input[type=number] {
      background-color: black;
    }

    .stat-entry-name {
        background-color: $lcars-light-pink;
        border-color: $lcars-light-pink;
        color: black;
    }

    .stat-entry-value {
        border-color: $lcars-light-pink;
    }
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none;
}
.modal-dialog {
  max-width: 500px;
  margin: 1.75rem auto;
}
.modal-dialog.modal-lg {
  width: 75%;
  max-width: 1000px;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #9179B7;
    color: white;
    border-radius: 20px;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    outline: 0;
}
.modal-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem 1rem;
}
.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
}
.dialog-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
    width: 50%;
    min-width: 300px;
    max-width: 500px;
    min-height: 5%;
    max-height: 90%;
    background-color: #9179B7;
    color: white;
    text-align: center;
    padding: 45px 15px 45px 15px;
    border-radius: 20px;
    overflow-y: scroll;
    z-index: 150;
}

.dialog-container .dialog-header {
  margin-top: -30px;
  text-align: left;
}

.modal-content .selection-header {
  color: #f7bbdd;
}

.modal-content table.selection-list td {
  border-bottom: 1px solid #aaaaaa;
}

.modal-content select {
  background-color: $lcars-light-pink;
}

.dialog-container .close, .modal-dialog .close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #B5B3D8;
  margin: -1rem -1rem -1rem auto;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 1rem 1rem;
}

.dialog-container.dialog-container-lg {
    width: 75%;
    max-width: 1000px;
}

.modal-dialog .sheet-selection-item {
    display: inline-block;
    padding: 16px;
    vertical-align: top;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 15px;
    box-sizing: border-box;
    width: 234px;
}

.modal-dialog .sheet-selection-item.selected {
    border: 1px solid white;
}

.modal-dialog .sheet-selection-item  .sheet-selection-item-name {
  width: 200px;
  min-height: 45px;
  text-align: center;
}

.modal-dialog .sheet-selection-item  .sheet-selection-item-thumbnail {
    position: relative;
    width: 200px;
    height: 260px;
}

.modal-dialog .sheet-selection-item  .sheet-selection-item-thumbnail img.thumbnail {
    width: 200px;
    height: auto;
}

.modal-dialog .sheet-selection-item  .sheet-selection-item-thumbnail img.overlay {
  position: absolute;
  left: 50%;
  top: 75px;
  transform: translateX(-50%);
}

@media screen and (min-width: 0px) and (max-width: 1000px) {

  .modal-dialog.modal-lg {
      width: 90%;
      max-width: 90%;
  }

  .modal-dialog .sheet-selection-item {
    padding: 12px;
    width: 166px;
  }

  .modal-dialog .sheet-selection-item  .sheet-selection-item-thumbnail {
    width: 140px;
    height: 183px;
  }

  .modal-dialog .sheet-selection-item  .sheet-selection-item-thumbnail img.thumbnail {
    width: 140px;
  }

  .modal-dialog .sheet-selection-item  .sheet-selection-item-name {
    width: 140px;
  }
}

.modal-dialog .style-selections {
    clear: both;
    text-align: left;
}

.dialog-container-visible {
    animation: dialog-in linear .2s;
}

/* ANIMATION */

@keyframes dialog-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}

@-webkit-keyframes dialog-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}

@-moz-keyframes dialog-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}

@-o-keyframes dialog-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}