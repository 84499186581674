
.sectorMap {
    height: 450px;
    width: 450px;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
    .sectorMap {
        height: auto;
    }
}

.lcars-decoration-left, .lcars-decoration-right {
    height: 450px;
    width: 125px;
}